<template>
	<div class="Mixcut">
		<div class="gongdan">
			<div class="dan">
				<div class="text"><span>*</span>付费方式:</div>
				<div class="inputbox">
					<el-radio v-model="payType" :label="1">在线支付</el-radio>
  					<el-radio v-model="payType" :label="2">积分支付</el-radio>
				</div>
			</div>
			<div class="dan">
				<div class="text"><span>*</span>产品类型:</div>
				<div class="inputbox">
					<el-cascader :options="Boxoptions" :v-model="boxValue" @change="BoxChange" :show-all-levels="false"></el-cascader>
				</div>
			</div>
			<div class="dan">
				<div class="text"><span>*</span>拍摄类型:</div>
				<div class="inputbox">
					<el-select v-model="paishevalue" @change="onpaisheChange" placeholder="请选择">
						<el-option v-for="item in paisheList" :key="item.value" :label="item.value" :value="item.value"></el-option>
					</el-select>
				</div>
			</div>
			<div class="dan">
				<div class="text"><span>*</span>秒数选择:</div>
				<div class="inputbox">
					<el-select v-model="miaoshuvalue" @change="onmiaoshuChange" placeholder="请选择">
						<el-option v-for="item in miaoshuList" :key="item.value" :label="item.value" :value="item.value"></el-option>
					</el-select>
				</div>
			</div>
			<div class="dan">
				<div class="text"><span>*</span>镜头数:</div>
				<div class="inputbox">
					<el-select v-model="jingtouvalue" @change="onjingtouChange" placeholder="请选择">
						<el-option v-for="item in jingtouList" :key="item.value" :label="item.value" :value="item.value"></el-option>
					</el-select>
				</div>
			</div>
			<!-- <div class="dan">
				<div class="text"><span>*</span>配音师:</div>
				<div class="inputbox">
					<el-select v-model="peiyinshivalue" @change="onpeiyinshiChange" placeholder="请选择">
						<el-option v-for="item in peiyinshiList" :key="item.id" :label="item.mixer + (item.status == 2 ? '（暂停接单，待配音师归来可下单）' : '')" :value="item.id" :disabled="item.status == 2"></el-option>
					</el-select>
				</div>
			</div> -->
			<div class="dan">
				<div class="text"><span>*</span>实拍占比:</div>
				<div class="inputbox">
					<el-select v-model="zhanbivalue" @change="onzhanbiChange" placeholder="请选择">
						<el-option v-for="item in zhanbiList" :key="item.value" :label="item.value" :value="item.value"></el-option>
					</el-select>
				</div>
			</div>
			<!-- <div class="dan">
				<div class="text">实拍文案:</div>
				<div class="inputbox">
					<el-input type="textarea" :rows="3" placeholder="如需自己提供文案请填写" v-model="WenanTextarea"></el-input>
				</div>
			</div> -->
			<div class="dan">
				<div class="text">视频格式:</div>
				<div class="inputbox">
					<el-input type="textarea" :rows="2" placeholder="" v-model="GeshiTextarea"></el-input>
					<div class="xiaohong">默认竖版（720*1280）如需横版请标注清楚格式</div>
				</div>
			</div>
			<div class="dan chang" style="margin-left:57px">
				<div class="text"><span>*</span>产品是否需要遮盖商标:</div>
				<div class="inputbox">
					<el-radio v-model="Shangbiaoradio" label="否">否</el-radio>
					<el-radio v-model="Shangbiaoradio" label="是">是</el-radio>
				</div>
			</div>
			<!-- <div class="dan chang">
				<div class="text"><span>*</span>产品是否加水印:</div>
				<div class="inputbox">
					<el-radio v-model="Shuiyinradio" label="否">否</el-radio>
					<el-radio v-model="Shuiyinradio" label="是">是</el-radio>
				</div>
			</div> -->
			<div class="dan maidian">
				<div class="text"><span>*</span>视频卖点:</div>
				<div class="inputbox">
					<el-input type="textarea" :rows="4" placeholder="越详细越好" v-model="MaidianTextarea"></el-input>
				</div>
			</div>
			<div class="dan">
				<div class="text">产品图片:</div>
				<div class="inputbox">
					<el-upload class="upload-demo" drag :action="origin + '/api/dub/uploadVideoProductImg?token=' + token" :limit="1" 
						ref="upload"
						accept=".jpeg,.jpg,.png,.gif,"
						show-file-list
						:file-list="fileListUpload"
						:on-exceed="onExceedtoupload"
						:on-remove="onRemoveupload"
						:on-success="onSuccessupload"
						:on-change="onChangeUpload">
						<i class="el-icon-upload"></i>
						<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
						<div class="el-upload__tip" slot="tip">最多可传 1 个，扩展名为：.jpeg,.jpg,.png,.gif,</div>
					</el-upload>
				</div>
			</div>
			<div class="dan">
				<div class="text"><span>*</span>产品链接:</div>
				<div class="inputbox">
					<el-input v-model="cpUrlinput" placeholder="请输入产品链接"></el-input>
				</div>
			</div>
			<div class="dan">
				<div class="text"><span>*</span>邮箱地址:</div>
				<div class="inputbox">
					<el-input v-model="Emailinput" placeholder="视频需要发送的邮箱"></el-input>
				</div>
			</div>
			<div class="dan">
				<div class="text">视频要求:</div>
				<div class="inputbox">
					<el-input type="textarea" :rows="3" placeholder="对拍摄的某些需要特写镜头展示等" v-model="YaoqiuTextarea"></el-input>
				</div>
			</div>
			<!-- <div class="dan">
				<div class="text">备注:</div>
				<div class="inputbox">
					<el-input type="textarea" :rows="2" placeholder="请填写备注" v-model="BeizhuTextarea"></el-input>
					<div class="xiaohong">提现产品价格/活动等内容备注。</div>
				</div>
			</div> -->
			<div class="dan">
				<div class="text">样品是否寄回:</div>
				<div class="inputbox">
					<el-input type="textarea" :rows="2" placeholder="如需寄回请填写地址（仅支持顺丰到付）" v-model="JihuiTextarea"></el-input>
				</div>
			</div>
			<div class="enderQueren">
				<el-button type="primary" @click="onSubmit" :disabled="delenteAnniu">确认提交</el-button>
				<el-button type="info" @click="onChongxin" :disabled="delenteAnniu">重新填写</el-button>
			</div>
		</div>
		<Payorder ref="Payorder"></Payorder>
	</div>
</template>

<script>
import Payorder from './payorder.vue'
export default {
	data () {
		return {
			token:JSON.parse(localStorage.getItem('TZ-USER')).token,
			payType:1,// 付费方式
			attachment_id:'',//上传文件只取ID
			fileListUpload:[],//上传的文件列表
			boxValue:'',//产品类型
			Boxoptions:[],//产品类型列表
			paishevalue:'',//拍摄类型
			paisheList:[{value: '种草'}],//拍摄类型
			miaoshuvalue:'',//秒数选择
			miaoshuList:[{value: '15秒'}, {value: '25秒'}, {value: '35秒'}],//秒数选择
			jingtouvalue:'',//镜头数
			jingtouList:[{value: '5个'}, {value: '7个'}, {value: '10个'}],//镜头数
			// peiyinshivalue:'',//配音师
			// peiyinshiList:[],//配音师
			zhanbivalue:'',//实拍占比
			zhanbiList:[{value: '50%'}, {value: '60%'}, {value: '70%'}, {value: '80%'}],//实拍占比
			// WenanTextarea:'',//实拍文案
			GeshiTextarea:'720*1280',//视频格式
			Shangbiaoradio:'否',//遮盖商标
			// Shuiyinradio:'否',//加水印
			MaidianTextarea:'',//视频卖点
			cpUrlinput:'',//产品链接
			Emailinput:'',//邮箱地址
			YaoqiuTextarea:'',//视频要求
			// BeizhuTextarea:'',//备注
			JihuiTextarea:'',//样品寄回
			delenteAnniu:false,//按钮开关
			origin:window.location.origin,
		}
	},
	components:{
		Payorder,
	},
	mounted(){
		this.onBoxoptions()
		// this.ongetMixerList()
	},

	methods: {
		handleClick(tab, event) {
			console.log(tab, event);
		},
		// 产品类型接口
		onBoxoptions(){
			this.$service.get(this.$api.getVideoTypeTree,{}, (res)=> {
				if(res.code == '200'){
					res.data.forEach((v,k) => {
						v.label = v.text
						v.value = v.id
						v.children.forEach((vc,kc) => {
							vc.label = vc.classify_name
							vc.value = vc.id
						})
					});
					this.Boxoptions = res.data
				}
			})
		},
		//产品类型
		BoxChange(value){
			this.boxValue = value[1]
		},
		//拍摄类型
		onpaisheChange(){
			// console.log(this.paishevalue)
		},//秒数选择
		onmiaoshuChange(){
			// console.log(this.miaoshuvalue)
		},//镜头数
		onjingtouChange(){
			// console.log(this.jingtouvalue)
		},
		// 配音师接口
		// ongetMixerList(){
		// 	this.$service.get(this.$api.getMixerList,{}, (res)=> {
		// 		if(res.code == '200'){
		// 			res.data.forEach((v,k) => {
		// 				v.label = v.mixer
		// 				v.value = v.id
		// 			});
		// 			this.peiyinshiList = res.data
		// 		}
		// 	})
		// },
		// 配音师
		onpeiyinshiChange(){
			// console.log(this.peiyinshivalue)
		},//实拍占比
		onzhanbiChange(){
			// console.log(this.zhanbivalue)
		},
		//文件上传成功，失败 所有都会调用
		onChangeUpload(){
			this.delenteAnniu = false
		},
		//文件上传超出
		onExceedtoupload(){
			this.$message({message: '上传文件已超出1个', type: 'warning'});
		},
		//文件上传成功
		onSuccessupload(res, file,fileList){
			if(res.code == 200){
				this.fileListUpload = fileList
				this.attachment_id = res.data
			}else{
				this.$refs.upload.uploadFiles.pop()//上传失败后移出刚上传的文件
				this.$message.error(res.msg);
				// console.log(this.$refs.upload.uploadFiles)
			}
			
		},
		//上传文件移出
		onRemoveupload(file, fileList){
			this.fileListUpload = fileList
		},
		//确认提交
		onSubmit(){
			if(this.boxValue == ''){this.$message({message: '请选择产品类型', type: 'warning'}); return}
			if(this.paishevalue == ''){this.$message({message: '请选择拍摄类型', type: 'warning'}); return}
			if(this.miaoshuvalue == ''){this.$message({message: '请选择秒数', type: 'warning'}); return}
			if(this.jingtouvalue == ''){this.$message({message: '请选择镜头数', type: 'warning'}); return}
			// if(this.peiyinshivalue == ''){this.$message({message: '请选择配音师', type: 'warning'}); return}
			if(this.zhanbivalue == ''){this.$message({message: '请选择实拍占比', type: 'warning'}); return}
			if(this.GeshiTextarea == ''){this.GeshiTextarea = '720*1280'}
			if(this.MaidianTextarea == ''){this.$message({message: '请填写视频买点', type: 'warning'}); return}
			if(this.cpUrlinput == ''){this.$message({message: '请填写产品链接', type: 'warning'}); return}
			if(this.Emailinput == ''){this.$message({message: '请填写邮箱地址', type: 'warning'}); return}
			var prame = {
				token:this.token,
				file_id:this.attachment_id,//上传文件id
				video_type:this.boxValue,//产品类型
				shoot_type:this.paishevalue,//拍摄类型
				second_num:this.miaoshuvalue,//秒数
				shot_num:this.jingtouvalue,//镜头数
				// mixer_id:this.peiyinshivalue,//配音师id
				will_share:this.zhanbivalue,//实拍占比
				// content:this.WenanTextarea,//文案
				is_brand:this.Shangbiaoradio,//遮盖商标
				// is_watermark:this.Shuiyinradio,//加水印
				model_format:this.GeshiTextarea,//视频格式
				product_link:this.cpUrlinput,//产品链接
				selling_points:this.MaidianTextarea,//视频卖点
				address:this.JihuiTextarea,//样品寄回
				ask:this.YaoqiuTextarea,//视频要求
				// priceandactivity_info:this.BeizhuTextarea,//活动详情
				email:this.Emailinput,//邮箱地址
				payment_method:this.payType,
				tab:2,
			}
			this.delenteAnniu = true
			this.$service.post(this.$api.uploadVideoOrder,prame, (res)=> {
				if(res.code == '200'){
					// this.$refs.Payorder.onImgmorny(res.data.img,res.data.money,res.data.order_num)
					// this.$refs.Payorder.togorouter('/tofilm/tofilmList')
					if(this._isMobile){
						this.$router.push('/tofilm/enterorder?order_num=' + res.data.order_num + '&payment_method=' + this.payType)
					}else{
						this.onentervideoOrderPay(res.data.order_num)
					}

					// this.onChongxin()
				}
			})
			this.delenteAnniu = false
		},
		onentervideoOrderPay(order_num){
			var param = {
				order_num:order_num,
			}
			this.$service.post(this.$api.videoOrderPay,param, (res)=> {
				if(res.code == '200'){
					this.$router.push('/tofilm/enterorder?order_num=' + res.data.order_num + '&payment_method=' + this.payType)
				}
			})
		},	
		//重新填写
		onChongxin(){
			this.attachment_id = '',//上传文件只取ID
			// this.boxValue = '',//产品类型
			this.paishevalue = '',//拍摄类型
			this.miaoshuvalue = '',//秒数选择
			this.jingtouvalue = '',//镜头数
			// this.peiyinshivalue = '',//配音师
			this.zhanbivalue = '',//实拍占比
			// this.WenanTextarea = '',//实拍文案
			this.GeshiTextarea = '',//视频格式
			this.Shangbiaoradio = '否',//遮盖商标
			// this.Shuiyinradio = '否',//加水印
			this.MaidianTextarea = '',//视频卖点
			this.cpUrlinput = '',//产品链接
			this.Emailinput = '',//邮箱地址
			this.YaoqiuTextarea = '',//视频要求
			// this.BeizhuTextarea = '',//备注
			this.JihuiTextarea = ''//样品寄回
			// console.log(this.boxValue)
		},
		//跳转列表
		tofilmList(){
			this.$router.push('/tofilm')
		},
	}
}
</script>

<style lang="scss" scoped>
.login{
	
}
</style>
