<template>
	<div class="tofilm">
		<div class="tofilm-top">
			<div class="tofilm-title">
				<div class="title">实拍下单工具</div>
				<div class="anniu"><a href="#list"><el-button type="primary" >历史订单</el-button></a></div>
				<div class="anniu"><a href="https://www.erlangcha.com/htmlText/35" target="_blank" rel="noopener noreferrer"> <el-button type="warning">实拍案例展示</el-button></a></div>
			</div>
			<div class="tofilm-prompt">
				<!-- <div class="text">实拍下单工作时间：周一到周五9:30 - 12:30  14:00 - 18:30</div> -->
			</div>
		</div>
		<div class="tofilm-main">
			<el-tabs v-model="activeName" type="border-card" @tab-click="ontabsClick">
				<el-tab-pane label="视频实拍" name="Videoilm">
					<Videoilm ref="Videoilm" v-if="activeName == 'Videoilm'"></Videoilm>
				</el-tab-pane>
				<!-- <el-tab-pane label="视频小程序混剪" name="Mixcut">
					<Mixcut ref="Mixcut" v-if="activeName == 'Mixcut'"></Mixcut>
				</el-tab-pane> -->
				<el-tab-pane label="视频小程序素材" name="Material">
					<Material ref="Material" v-if="activeName == 'Material'"></Material>
				</el-tab-pane>
			</el-tabs>
		</div>
		
		<TofilmList ref="tofilmList" id="list"></TofilmList>
	</div>
</template>

<script>
import Videoilm from './Videoilm.vue'
import Mixcut from './Mixcut.vue'
import Material from './Material.vue'
import TofilmList from './tofilmList.vue'
export default {
	data () {
		return {
			activeName: 'Videoilm',//选项卡
		}
	},
	components:{
		Videoilm,
		TofilmList,
		Mixcut,
		Material,
	},
	mounted(){
		if(this._isMobile && this._isMicrom){alert('请用微信打开，否则不能在线支付')}
		this.onGongzuo()
	},

	methods: {
		//选项卡切换
		ontabsClick(tab, event) {
			// console.log(tab, event);
		},
		onGongzuo(){
			this.$service.get(this.$api.getNowTime,{}, (res)=> {
				if(res.code == '200'){
					if(res.data.time){
						this.$alert(res.data.text, '提示', {
							confirmButtonText: '确定',
						});
					}
				}
			})
		},
	}
}
</script>

<style lang="scss" scoped>
.tofilm{
	.tofilm-main{
		margin: 20px 0;
	}
	.tofilm-top{
		border-bottom: 1px solid #ededed;
		padding-bottom: 5px;
		.tofilm-title{
			display: flex;
			font-weight: 500;
			padding-bottom: 15px;
			.title{
				font-size: 22px;
			}
			.anniu{
				margin-left: 10px;
			}
		}
		.tofilm-prompt{
			display: flex;
			justify-content: space-between;
			.horn{
				padding: 5px 10px;
				background: #fefae9;
				color: #ff9a56;
			}
		}
	}
	.gongdan{
		margin-top: 20px;
		.dan.maidian{
			align-items: end;
			.text{
				margin-top: 40px;
			}
		}
	}
}

</style>
<style lang="scss">
.tofilm{
	.gongdan{
		.dan{
			.el-upload{
				width: 100%;
				.el-upload-dragger{
					height: 100px;
					width: 100%;
				}
				.el-icon-upload{
					margin-top: 4px;
				}
			}
			
		}
	}
}
.zhifuModai{
	width: 400px;
	.el-dialog__body{
		padding-top: 10px;
	}
	.zhifu{
		text-align: center;
		.image{
			width: 250px;
			height: 250px;
			margin: 0 auto;
		}
		.zhifunum{
			font-size: 24px;
			span{
				color: #f00;
				font-size: 32px;
				font-weight: 500;
			}
		}
		.text{
			color: #029a02;
			i{
				margin-right: 10px;
			}
		}
		.wenzi{
			font-size: 16px;
			margin-top: 10px;
			span{
				font-size: 16px;
			}
			.sucee{
				color: #f00;
			}
			.shibai{
				color: #ff9a56;
			}
			.todingdan{
				cursor: pointer;
				color: #2251db;
			}
		}
	}
}
@media screen and (max-width: 750px) {
	.tofilm{
		.tofilm-top{
			.tofilm-title{
				align-items: center;
				.anniu{
					// width: 2rem;
					display: inline-block;
					margin-left:.1rem !important;
					margin-right: .1rem;
				}
			}
			.tofilm-prompt{
				display: block;
				.text{
					margin-bottom: 10px;
				}
			}
		}
		.gongdan{
			width: 6.3rem;
			.dan{
			width: 6.3rem;
				&.chang .text{ width: 3rem;}
				&.chang .inputbox{ width: 3rem;}
				.text{
					width: 1.8rem;
				}
				.inputbox{
					width: 5.7rem;
				}
			}
			.enderQueren{
				padding-bottom: .5rem;
				padding-top: 0;
				margin: 0 auto;
			}
		}
	}
}
</style>
